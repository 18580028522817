<template>
  <div class="bk-main-canvas">
    <AppHeader />

    <NuxtPageDependency>
      <Breadcrumb :links="breadcrumb" />
    </NuxtPageDependency>

    <div class="page-content">
      <NuxtPage />
    </div>

    <AppFooter />

    <ClientOnly>
      <CountryPopup />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const language = useCurrentLanguage()

useHead({
  htmlAttrs: {
    lang: language.value,
  },
})

const breadcrumb = useBreadcrumbLinks()
</script>

<style lang="postcss">
.page-content {
  min-height: 80vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.3s cubic-bezier(0.43, 0.72, 0.56, 0.99);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
