
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'arrow': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/arrow.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 16.878 19.499","id":"arrow"} },
  'arrow_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/arrow_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"13.196","height":"14.816","id":"arrow_mobile"} },
  'bullet': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/bullet.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"9.17","height":"13.365","id":"bullet"} },
  'bullet_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/bullet_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"7.504","height":"10.799","id":"bullet_mobile"} },
  'check-bold-white': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/check-bold-white.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"11","viewBox":"-0.5 0 12 10","id":"check-bold-white"} },
  'check-bold': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/check-bold.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"11","viewBox":"-0.5 0 12 10","id":"check-bold"} },
  'check': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/check.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"11","id":"check"} },
  'chevron-down': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-down.svg?raw').then(v => v.default), attributes: {"width":"12","height":"8","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-down"} },
  'chevron-down_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-down_mobile.svg?raw').then(v => v.default), attributes: {"width":"12","height":"8","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-down_mobile"} },
  'chevron-left-white-bold': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-left-white-bold.svg?raw').then(v => v.default), attributes: {"width":"9.17","height":"13.365","xmlns":"http://www.w3.org/2000/svg","xmlns:svg":"http://www.w3.org/2000/svg","id":"chevron-left-white-bold"} },
  'chevron-left-white-bold_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-left-white-bold_mobile.svg?raw').then(v => v.default), attributes: {"width":"9.17","height":"13.365","xmlns":"http://www.w3.org/2000/svg","xmlns:svg":"http://www.w3.org/2000/svg","id":"chevron-left-white-bold_mobile"} },
  'chevron-left-white': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-left-white.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-left-white"} },
  'chevron-left-white_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-left-white_mobile.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-left-white_mobile"} },
  'chevron-left': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-left.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-left"} },
  'chevron-left_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-left_mobile.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-left_mobile"} },
  'chevron-right-white-bold': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-right-white-bold.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"9.17","height":"13.365","id":"chevron-right-white-bold"} },
  'chevron-right-white-bold_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-right-white-bold_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"9.17","height":"13.365","id":"chevron-right-white-bold_mobile"} },
  'chevron-right-white': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-right-white.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-right-white"} },
  'chevron-right-white_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-right-white_mobile.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-right-white_mobile"} },
  'chevron-right': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-right.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-right"} },
  'chevron-right_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-right_mobile.svg?raw').then(v => v.default), attributes: {"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-right_mobile"} },
  'chevron-up': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-up.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"8","id":"chevron-up"} },
  'chevron-up_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/chevron-up_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"8","id":"chevron-up_mobile"} },
  'cross': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/cross.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"15.121","height":"15.121","id":"cross"} },
  'cross_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/cross_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"14.414","height":"14.414","id":"cross_mobile"} },
  'dot': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/dot.svg?raw').then(v => v.default), attributes: {"id":"dot","data-name":"Layer 2","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 14 14"} },
  'download': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/download.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"19","height":"17","id":"download"} },
  'download_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/download_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"12.5","id":"download_mobile"} },
  'facebook': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/facebook.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"14.803","height":"28.346","id":"facebook"} },
  'heart': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/heart.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"9.998","height":"8.823","id":"heart"} },
  'info': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/info.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","id":"info"} },
  'instagram': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/instagram.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"26.156","height":"26.156","id":"instagram"} },
  'linkedin': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/linkedin.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"21.647","height":"21.784","id":"linkedin"} },
  'mail': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/mail.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"19","height":"15","id":"mail"} },
  'mail_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/mail_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"11","id":"mail_mobile"} },
  'menu': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/menu.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"17","height":"17","id":"menu"} },
  'menu_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/menu_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"17","height":"16","id":"menu_mobile"} },
  'minus': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/minus.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"19","height":"3","id":"minus"} },
  'minus_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/minus_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"2","id":"minus_mobile"} },
  'pin': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/pin.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"15.148","height":"22.725","id":"pin"} },
  'pin_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/pin_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"11.148","height":"17.279","id":"pin_mobile"} },
  'play': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"38","height":"45","id":"play"} },
  'plus': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/plus.svg?raw').then(v => v.default), attributes: {"data-name":"Akkordeon Icon","xmlns":"http://www.w3.org/2000/svg","width":"19","height":"19","id":"plus"} },
  'plus_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/plus_mobile.svg?raw').then(v => v.default), attributes: {"data-name":"Akkordeon Icon","xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","id":"plus_mobile"} },
  'search': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/search.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"16.512","height":"17.656","id":"search"} },
  'search_mobile': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/search_mobile.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"16.158","height":"17.303","id":"search_mobile"} },
  'tiktok': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/tiktok.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"22.272","height":"25.635","id":"tiktok"} },
  'twitter': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/twitter.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"25.129","height":"20.416","id":"twitter"} },
  'youtube': { import: () => import('/builds/bazinga/exped/frontend/assets/symbols/youtube.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","width":"27.469","height":"19.354","id":"youtube"} },
}
  