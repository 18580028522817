<template>
  <div class="container my-30 md:my-50">
    <p class="uppercase text-xs mb-17">
      {{ $texts('onThisPageTitle', 'On this page') }}
    </p>
    <ul class="grid gap-[1em] text-xs">
      <li v-for="(link, i) in links" :key="i">
        <a
          class="link font-bold uppercase gap-[0.5em] flex"
          :href="'#' + link.anchorId"
        >
          <SpriteSymbol name="arrow" class="size-[1em] rotate-90 mt-[0.15em]" />
          <span>{{ link.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type {
  ParagraphFromLibraryFragment,
  ParagraphTitleFragment,
} from '#build/graphql-operations'
import type { FieldListItem } from '#blokkli/types'
import { notNullish } from '@vueuse/core'

const { rootBlocks } = defineBlokkliFragment({
  name: 'anchor_nav',
  label: 'Anchor Navigation',
  description: 'Displays an anchor navigation.',
})

const links = computed(() => {
  return rootBlocks.value
    .map((v: FieldListItem) => {
      if (!v.options || typeof v.options !== 'object') {
        return
      }

      let showInAnchor = v.options.showInAnchor
      let anchorId = v.options.anchorId || null
      let title: null | string = null

      if (v.bundle === 'title') {
        const props = v.props as ParagraphTitleFragment | undefined | null
        title = props?.anchorTitle || props?.title || null
      } else if (v.bundle === 'from_library') {
        const props = v.props as ParagraphFromLibraryFragment
        if (props.libraryItem && props.libraryItem.block) {
          const nestedItem = props.libraryItem.block
          if (showInAnchor === null || showInAnchor === undefined) {
            showInAnchor = nestedItem.options?.showInAnchor
          }

          if (anchorId === null || anchorId === undefined) {
            anchorId = nestedItem.options?.anchorId
          }

          if (nestedItem.props) {
            const nestedProps = nestedItem.props as ParagraphTitleFragment
            title = nestedProps.anchorTitle || nestedProps.title || null
          }
        }
      }

      if (!title) {
        return null
      }

      if (!anchorId) {
        anchorId = 'p-' + v.uuid
      }

      return {
        title,
        anchorId,
      }
    })
    .filter(notNullish)
})
</script>
