<template>
  <NodePage v-if="node && 'hero' in node" v-bind="node" />
  <NodeBlogArticle v-else-if="node && 'tagsField' in node" v-bind="node" />
  <GroupGroupB2b v-else-if="node && '__typename' in node" v-bind="node" />
</template>

<script lang="ts" setup>
import type {
  GroupFragment,
  NodeBlogArticleFragment,
  NodePageFragment,
} from '#graphql-operations'

defineOptions({
  name: 'PageRoute',
})

definePageMeta({
  name: 'drupal-route',
  path: '/:slug(.*)*',
  languageMapping: {
    en: '/:slug(.*)*',
    de: '/de/:slug(.*)*',
    fr: '/fr/:slug(.*)*',
  },
  drupalRoute: true,
})

const nuxtRoute = useRoute()

const { data: query } = await useAsyncGraphqlQuery(
  'route',
  {
    path: nuxtRoute.path,
  },
  {
    graphqlCaching: {
      client: true,
    },
    transform: function (data) {
      return data.data
    },
  },
)

defineSchemaOrg(query)

const { entity: node } = await useDrupalRoute<
  NodePageFragment | NodeBlogArticleFragment | GroupFragment
>(query.value)

setLanguageLinksFromRoute(query.value)
useRouteCache((c) => c.setCacheable().setMaxAge(600))
setBreadcrumbLinksFromRoute(query.value)
await renderPageDependencies()
</script>
