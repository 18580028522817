<template>
  <div
    v-if="isVisible"
    class="paragraph-title scroll-mt-[170px]"
    :id="anchorId"
    :class="[
      {
        container: !parentType,
        [marginClasses]: !parentType,
        'mb-20': parentType,
      },
      globalClasses,
    ]"
  >
    <component :is="tag" v-blokkli-editable:field_title :class="classes">
      {{ title }}
    </component>
  </div>
</template>

<script lang="ts" setup>
const { options, parentType, uuid } = defineBlokkli({
  bundle: 'title',
  globalOptions: ['region', 'marginTop'],
  options: {
    hasBorder: {
      type: 'checkbox',
      label: 'Border',
      default: false,
    },
    headingLevel: {
      type: 'radios',
      default: 'h2',
      label: 'Heading',
      options: {
        h2: 'H2',
        h3: 'H3',
      },
    },
    showInAnchor: {
      type: 'checkbox',
      label: 'Show in anchor navigation',
      default: false,
    },
    anchorId: {
      type: 'text',
      label: 'Anchor ID',
      default: '',
    },
  },
  editor: {
    previewWidth: 512,
    editTitle: (el) =>
      (el.querySelector('h2') || el.querySelector('h3'))?.innerText,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  title: string
  anchorTitle?: string
}>()

const anchorId = computed(() => options.value.anchorId || 'p-' + uuid)

const tag = computed(() => options.value.headingLevel || 'h2')

const classes = computed(() => {
  if (options.value.hasBorder) {
    return 'is-small heavy pb-5 border-b lg:border-b-2'
  }
  return options.value.headingLevel === 'h3' ? 'is-3' : 'is-2'
})
</script>
