
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'arrow'
  | 'arrow_mobile'
  | 'bullet'
  | 'bullet_mobile'
  | 'check-bold-white'
  | 'check-bold'
  | 'check'
  | 'chevron-down'
  | 'chevron-down_mobile'
  | 'chevron-left-white-bold'
  | 'chevron-left-white-bold_mobile'
  | 'chevron-left-white'
  | 'chevron-left-white_mobile'
  | 'chevron-left'
  | 'chevron-left_mobile'
  | 'chevron-right-white-bold'
  | 'chevron-right-white-bold_mobile'
  | 'chevron-right-white'
  | 'chevron-right-white_mobile'
  | 'chevron-right'
  | 'chevron-right_mobile'
  | 'chevron-up'
  | 'chevron-up_mobile'
  | 'cross'
  | 'cross_mobile'
  | 'dot'
  | 'download'
  | 'download_mobile'
  | 'facebook'
  | 'heart'
  | 'info'
  | 'instagram'
  | 'linkedin'
  | 'mail'
  | 'mail_mobile'
  | 'menu'
  | 'menu_mobile'
  | 'minus'
  | 'minus_mobile'
  | 'pin'
  | 'pin_mobile'
  | 'play'
  | 'plus'
  | 'plus_mobile'
  | 'search'
  | 'search_mobile'
  | 'tiktok'
  | 'twitter'
  | 'youtube'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.6lBa6wIp1h.svg"}

export type RuntimeOptions = {
  ariaHidden: boolean
}

export const runtimeOptions: RuntimeOptions = {"ariaHidden":false}
